<template>
  <section class="ministry-about">
    <div class="ministry-about__article" v-if="department.description">
      <EditorJSComponent :text="department | getArticle" />
    </div>
    <div class="info-card">
      <ul>
        <li v-if="department.short_title"><b>Сокращенное наименование: </b>{{ department.short_title }}</li>
        <li v-if="department.address"><b>Адрес: </b>{{ department.address }}</li>
        <li v-if="department.schedule">
          <pre><b>График (режим) работы: </b>{{ department.schedule }}</pre>
        </li>
      </ul>
      <ul>
        <li v-if="department.phone"><b>Телефон: </b>{{ department.phone }}</li>
        <li v-if="department.fax"><b>Факс: </b>{{ department.fax }}</li>
        <li v-if="department.email"><b>Email: </b>{{ department.email }}</li>
        <li v-if="department.inn"><b>ИНН: </b>{{ department.inn }}</li>
        <li v-if="department.kpp"><b>КПП: </b>{{ department.kpp }}</li>
        <li v-if="department.ogrn"><b>ОГРН: </b>{{ department.ogrn }}</li>
      </ul>
    </div>
    <PersonComponent small :data="director" />
    <!--    <div class="ministry-about__links">-->
    <!--      <ActivityItem :data="{ title: 'Руководство', to: { name: 'ministry-management' } }" white />-->
    <!--      <ActivityItem-->
    <!--        :data="{ title: 'Подведомственные учреждения', to: { name: 'ministry-departments' } }"-->
    <!--        white-->
    <!--      />-->
    <!--      <ActivityItem :data="{ title: 'Цели и задачи', to: { name: 'ministry-goals' } }" white />-->
    <!--    </div>-->
  </section>
</template>

<script>
import PersonComponent from "components/PersonComponent.vue";
// import ActivityItem from "components/ActivityItem.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import MINISTER from "gql/queries/minister.graphql";

export default {
  name: "MinistryAboutPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: MINISTER,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  computed: {
    department() {
      return this.$store.state.department;
    },
    director() {
      return this.$store.state.minister;
    },
  },
  metaInfo: {
    title: "О министерстве",
  },
  components: {
    EditorJSComponent,
    // ActivityItem,
    PersonComponent,
  },
};
</script>

<style lang="stylus">
.ministry-about {
  display flex
  flex-direction column
  gap 50px
  align-items stretch
  height 100%
  +below(860px) {
    gap 15px
  }

  .info-card {
    +below(1460px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
    +below(1280px) {
      grid-template-columns 1fr 1fr
      grid-gap 30px
      margin: 0 -30px;
      border-radius: 0;
    }
    +below(860px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
  }

  &__article {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    +below(1280px) {
      font-size: 1em;
      line-height: 26px;
    }
  }

  &__links {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px

    +below(860px) {
      grid-template-columns repeat(2, 1fr)
      grid-gap 15px
    }
    +below(600px) {
      display flex
      flex-direction column
      .activity-item {
        margin-top 10px
      }
    }
    +below(420px) {
      grid-template-columns 1fr
    }
  }
}

.info-card {
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;
  padding 30px
  display grid
  grid-template-columns 1fr 1fr
  grid-gap 30px
  align-items flex-start

  ul {
    display grid
    grid-gap 15px
    width: 100%;

    li {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 28px;
      color: var(--dark);

      pre {
        white-space break-spaces
      }
    }
  }
}
</style>
